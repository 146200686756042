/** @jsx jsx */
import {jsx} from '@emotion/core'

import Logo from '../../components/logo'
import Section from '../../components/section'
import {Form, Radio, Typography} from 'antd'

import FileUploader from '../../components/file-uploader'
import useScreen from '../../hooks/use-screen'
import Navigation from '../../components/navigation'

import FormComposer from '../../components/form-composer'
import useTranslate from '../../hooks/use-translate'
import {useRecoilValue} from 'recoil'
import {clientState} from 'src/state'

const ScreenAlerisReferral = () => {
  const {form, storedValue, isSaving, currentPage, updateState} = useScreen()

  const client = useRecoilValue(clientState)

  const {t} = useTranslate({
    clientData: {
      ...client,
    },
  })

  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={{
        ...storedValue,
      }}
      autoComplete="off"
    >
      <Section>
        <Logo />
      </Section>
      <Section>
        <div>{t('SCREEN_REFERRAL_LINE1')}</div>
      </Section>
      <Section>
        {currentPage?.title && (
          <Typography.Title>{t(currentPage?.title)}</Typography.Title>
        )}

        <Form.Item
          label={
            <div className="labelRequired">{t('SCREEN_REFERRAL_CHOOSE')}</div>
          }
        >
          <div className="radioOption">
            <Radio
              value={'form'}
              checked={storedValue.referralType === 'form'}
              onClick={() =>
                updateState(currentState => ({
                  ...currentState,
                  referralType: 'form',
                  files: [],
                }))
              }
            >
              {t('SCREEN_REFERRAL_CHOOSE_FORM')}
            </Radio>
          </div>
          <div className="radioOption">
            <Radio
              value={'upload'}
              checked={storedValue.referralType === 'upload'}
              onClick={() =>
                updateState(currentState => ({
                  ...currentState,
                  referralType: 'upload',
                }))
              }
            >
              {t('SCREEN_REFERRAL_CHOOSE_UPLOAD')}
            </Radio>
          </div>
        </Form.Item>

        {storedValue.referralType === 'form' && (
          <FormComposer
            formInstance={form}
            form={currentPage?.form}
            storedValue={storedValue}
            setValue={updateState}
            isSaving={isSaving}
          />
        )}

        {storedValue.referralType === 'upload' && (
          <FileUploader
            storedValue={storedValue}
            updateState={updateState}
            form={form}
          />
        )}
      </Section>
      <Navigation form={form} />
    </Form>
  )
}

export default ScreenAlerisReferral
